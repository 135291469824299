import User from '@/Models/User/User';
import Tenant from '@/Models/Tenant/Tenant';
import {TenantRole} from '@/Models/Tenant/TenantRole';
import ScimGroup from '@/Models/SCIM/ScimGroup';

/**
 * Membership relation between user and tenants with its attached TenantRole.
 */
export class TenantMemberRole {

    public readonly user_uid: string;
    public readonly tenant_uid: string;
    public readonly scim_group_uid: string | null;

    public readonly user?: User | null;
    public readonly tenant?: Tenant | null;
    public readonly role: TenantRole;
    public readonly scim_group?: ScimGroup | null;

    constructor(attributes: any = {}) {
        this.user_uid = attributes.user_uid;
        this.tenant_uid = attributes.tenant_uid;
        this.scim_group_uid = attributes.scim_group_uid;

        this.user = attributes.user ? new User(attributes.user) : attributes.user;
        this.tenant = attributes.tenant ? new Tenant(attributes.tenant) : attributes.tenant;
        this.role = new TenantRole(attributes.role);
        this.scim_group = attributes.scim_group ? new ScimGroup(attributes.scim_group) : attributes.scim_group;
    }

    get isScimMapped() {
        return this.scim_group_uid !== null;
    }
}
