<template>
    <div class="container">
        <div class="row justify-content-center">
            <div class="col-md-12 max-content-width">
                <h1 v-text="headline" />
                <p v-text="headline_sub" />
                <div class="card">
                    <div class="card-body">
                        <div class="row justify-content-center d-flex">
                            <div class="col-lg-4 justify-content-center d-flex">
                                <img alt="" class="mw-100" src="/images/undraw_my_password.svg">
                            </div>
                            <div class="col-lg-6">
                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <p class="first-line" v-html="firstLine" />

                                <!-- eslint-disable-next-line vue/no-v-html -->
                                <p class="second-line" v-html="secondLine" />

                                <ButtonPrimary
                                    :caption="buttonCaption"
                                    @trigger="switchTenant"
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script lang="ts">

import {trans} from '@/Utility/Helpers';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import EventType from '@/Utility/EventType.js';
import AuthorizationError from '@/Errors/AuthorizationError.js';
import {defineComponent, inject} from 'vue';
import {tenantServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import Tenant from '@/Models/Tenant/Tenant';
import type User from '@/Models/User/User';

export default defineComponent({
    components: {
        ButtonPrimary,
    },

    props: {
        tenant: {
            type: Tenant,
            required: true,
        },
    },

    data() {
        return {
            tenantService: inject(tenantServiceKey)!,
        };
    },

    computed: {

        currentUser() {
            return window.currentUser! as User;
        },

        headline() {
            return trans('tenants.accept_invitation.headline', { name: this.currentUser.fullName });
        },

        headline_sub() {
            return trans('tenants.accept_invitation.headline_sub', { team: this.tenant.name });
        },

        firstLine() {
            return trans('tenants.accept_invitation.first_line', { team: this.tenant.name });
        },

        secondLine() {
            return trans('tenants.accept_invitation.second_line');
        },

        buttonCaption() {
            return trans('tenants.accept_invitation.button', { team: this.tenant.name });
        },
    },

    methods: {
        trans,

        switchTenant() {
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.changing_tenant'));

            this.tenantService
                .switchTenantAndReload(this.tenant, '/')
                .catch(this.onErrorApi);
        },

        onErrorApi(error: string | Error) {
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);

            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root?.forceLogout;
            }

            this.$root?.showErrorDialog(error);
        },
    },
});
</script>

<style lang="scss" scoped>
.card {
    border: none;
    border-radius: 4px;
    margin-top: 32px;
}

.card-body {
    padding-top: 80px;
    padding-bottom: 64px;
}

.max-content-width {
    max-width: 1035px;
}

.first-line {
    margin-top: 2rem;
    margin-bottom: 1rem;
}

.second-line {
    margin-bottom: 1.5rem;
}

</style>
