<template>
    <main id="layout-main">
        <PageHeader
            :page-title="trans('statistics.index.headline')"
        />

        <div id="layout-content">
            <div id="content">

                <div class="tab-wrapper">
                    <Tabs :tabs="tabs" />
                </div>

                <StatisticsCards v-if="tabs.statistics.active" class="tab-component" />

                <LearningRecordsStatisticsCards v-if="tabs.learningRecordsStatistics.active" class="tab-component" />

                <LearningRecordsTable v-if="tabs.learningRecords?.active" class="tab-component" />
            </div>

            <ModalNotification />
        </div>
    </main>
</template>

<script lang="ts">

import PageHeader from '@/Vue/Common/PageHeader.vue';
import {trans} from '@/Utility/Helpers';
import Tabs from '@/Vue/Common/Tabs.vue';
import {defineComponent} from 'vue';
import DefaultTab from '@/Utility/Tabs/DefaultTab';
import StatisticsCards from '@/Vue/Statistics/General/StatisticsCards.vue';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import LearningRecordsTable from '@/Vue/Statistics/LearningRecordsTable/LearningRecordsTable.vue';
import LearningRecordsStatisticsCards
    from '@/Vue/Statistics/LearningRecordsDashboards/LearningRecordsStatisticsCards.vue';
import {Permission} from '@/Models/User/Permission';

export default defineComponent({
    name: 'PageStatistics',

    components: {
        LearningRecordsStatisticsCards,
        LearningRecordsTable,
        ModalNotification,
        StatisticsCards,
        Tabs,
        PageHeader
    },

    props: {},

    data() {
        const tabs: Record<string, DefaultTab> = {
            statistics: new DefaultTab(trans('statistics.index.tab_general'), true),
            learningRecordsStatistics: new DefaultTab(trans('statistics.index.tab_learning_records_dashboard')),
        };

        if (this.$gate.allows(Permission.StatisticsReadPersonalData())) {
            tabs.learningRecords = new DefaultTab(
                trans('statistics.index.tab_learning_records_table_view'),
                false,
                !this.$gate.allows(Permission.StatisticsReadPersonalData()),
            );
        }

        return {
            tabs: tabs,
        };
    },

    methods: {
        trans,
    },
});
</script>

<style lang="scss" scoped>

#content {
    padding: 0;
}

.tab-wrapper {
    position: sticky;
    top: 0;
    left: 0;
    right: 0;
    padding: 0 50px 15px 50px;
    background-color: var(--background-color-light);
    z-index: 1;

    .tabs {
        max-width: 640px;
    }
}

.tab-component {
    padding: 20px 50px 35px 50px;
}

</style>
