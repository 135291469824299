<template>
    <main id="layout-main" :data-loading="isLoading" :data-deleting="isDeleting">

        <PageHeader
            :page-title="trans('tenants.index.headline')"
        />

        <div id="layout-content">
            <div id="content" v-not-focusable>
                <div class="container tenant-list-filters-wrapper">
                    <ButtonPrimary
                        v-if="canCreateTenants"
                        :href="$root.route('tenants.createForm')"
                        caption="labels.create_tenant"
                        class="btn-add-tenant"
                        icon="icon_add"
                    />
                </div>
                <div class="container">
                    <table class="tenants-list" width="100%">
                        <TenantListItem
                            v-for="tenant in tenants"
                            :key="tenant.uid"
                            :tenant="tenant"
                            @switch="switchTenant"
                            @delete="deleteTenant"
                        />
                    </table>
                </div>
            </div>

            <ModalNotification/>

            <ModalProgress/>

            <ModalApplyCancel
                event-type="MODAL_DELETE_TENANT"
                :title="trans('tenants.delete.modals.confirm.title')"
                :description="deleteTenantModalDescription"
                apply-text="tenants.delete.modals.confirm.apply"
            />

        </div>
    </main>
</template>

<script>

    // Import VueJS components:
    import TenantListItem from '@/Vue/Tenants/TenantListItem';

    // Import classes:
    import EventType from '@/Utility/EventType';
    import AuthorizationError from '@/Errors/AuthorizationError';
    import {permission, trans} from '@/Utility/Helpers';
    import {Permission} from '@/Models/User/Permission';
    import {inject} from "vue";
    import {tenantServiceKey} from "@/Vue/Bootstrap/InjectionKeys";
    import ModalApplyCancel from "@/Vue/Modals/ModalApplyCancel.vue";
    import ModalNotification from "@/Vue/Modals/ModalNotification.vue";
    import ModalProgress from "@/Vue/Modals/ModalProgress.vue";

    export default {
        name: 'PageTenantList',

        props: {},

        components: {
            ModalProgress,
            ModalNotification,
            ModalApplyCancel,
            TenantListItem,
        },

        data() {
            return {
                tenantService: inject(tenantServiceKey),
                /**
                 * @var {TenantWithDetails|null}
                 */
                tenantToDelete: null,
            }
        },

        mounted() {
            this.$globalEvents.on(EventType.MODAL_DELETE_TENANT_APPLY, this.onApplyConfirmDelete);

            // Fetch tenants for the user:
            this.fetchTenants();
        },

        beforeUnmount() {
            this.$globalEvents.off(EventType.MODAL_DELETE_TENANT_APPLY, this.onApplyConfirmDelete);
        },

        computed: {

            canCreateTenants() {
                return permission(Permission.TenantsCreate());
            },

            /**
             * Get the list of tenants from the service
             *
             * @returns {TenantWithDetails[]}
             */
            tenants() {
                return this.tenantService.tenants;
            },

            /**
             * Loading state
             *
             * @returns {Boolean}
             */
            isLoading() {
                if (this.tenantService.isLoading) {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            },

            /**
             * Deleting state
             *
             * @returns {Boolean}
             */
            isDeleting() {
                if (this.tenantService.isDeleting) {
                    this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('tenants.delete.modals.deleting.title'));
                    return true;
                }
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            },

            deleteTenantModalDescription() {
                if (!this.tenantToDelete) {
                    return '';
                }

                const transKey = permission(Permission.TenantsDisplayUid()) ?
                    'tenants.delete.modals.confirm.description_uid' :
                    'tenants.delete.modals.confirm.description';

                return trans(transKey, {
                    'name': this.tenantToDelete.name,
                    'uid': this.tenantToDelete.uid,
                });
            },

            /**
             * @return {string}
             */
            currentTenantUid() {
                return window.currentUser.tenant.uid;
            },
        },

        methods: {

            /**
             * @param {TenantWithDetails} tenant tenant to work in from now on
             */
            switchTenant(tenant) {
                this.tenantService
                    .switchTenantAndReload(tenant)
                    .catch(this.onErrorApi);
                return this;
            },

            /**
             * @param {TenantWithDetails} tenant
             */
            deleteTenant(tenant) {
                this.tenantToDelete = tenant;
                this.$globalEvents.emit(EventType.MODAL_DELETE_TENANT_SHOW, tenant);
            },

            /**
             * @param {TenantWithDetails} tenant
             */
            onApplyConfirmDelete(tenant) {
                this.tenantService
                    .deleteTenant(tenant)
                    .catch(this.onErrorApi)
                    .then(() => {
                        if (tenant.uid === this.currentTenantUid) {
                            // reload page to update current tenant
                            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                            window.location.reload();
                        } else {
                            // reload data only - current tenant is still available
                            return this.fetchTenants();
                        }
                    });
            },

            /**
             * Fetch tenants for the current user from API
             */
            fetchTenants() {
                this.tenantService
                    .fetchTenants()
                    .catch(this.onErrorApi);
                return this;
            },

            /**
             * Error handler for API errors
             *
             * @param {String} error
             */
            onErrorApi(error) {
                // Force logout for authorization errors:
                if (error instanceof AuthorizationError) {
                    error.callback = this.$root.forceLogout;
                }

                this.$root.showErrorDialog(error);
                return this;
            },
        }
    }
</script>

<style lang="scss" scoped>

</style>
