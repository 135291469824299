<template>
    <div class="inspector-panel add-user-to-tenant">

        <div class="property property-headline">
            <h3>{{ trans('users.invitation.add_user_to_tenant.headline') }}</h3>
            <p>{{ trans('users.invitation.add_user_to_tenant.description') }}</p>
        </div>

        <div class="property">
            <TextInput
                :key="'email_'+key"
                :label="trans('labels.email')"
                :maxlength="50"
                :model="form"
                :required="true"
                :validation-errors="validationErrors('email')"
                property="email"
                type="email"
                @change="removeValidationError('email')"
            />
        </div>

        <div class="property">
            <Dropdown
                :key="'tenantrole_'+key"
                :class="(validationErrors('tenant_role_uid').length > 0 ? ' is-invalid' : '')"
                :model="form"
                :options="tenantRoleOptions"
                :required="true"
                :label="trans('labels.tenant_role')"
                property="tenant_role_uid"
                @select="removeValidationError('tenant_role_uid')"
            />
        </div>

        <div class="property property-submit-button">

            <ButtonPrimary
                :key="'button_add_'+key"
                :caption="buttonCaption"
                @trigger="addUser"
            />

            <div
                v-if="canAddUsers"
                class="form-check checkbox-invite"
            >
                <input
                    id="invite_user_checkbox"
                    :key="'invite_users_'+key"
                    v-model="inviteUsers"
                    :false-value="false"
                    :true-value="true"
                    class="form-check-input"
                    name="invite_user"
                    type="checkbox"
                >
                <label class="form-check-label" for="invite_user_checkbox">
                    {{ trans('labels.send_invitation') }}
                </label>
            </div>

        </div>
    </div>

</template>

<script lang="ts">

import type { PropType} from 'vue';
import {defineComponent, inject} from 'vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import {shortId, trans} from '@/Utility/Helpers';
import {tenantServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import DropdownOption from '@/Utility/DropdownOption';
import Dropdown from '@/Vue/Common/Dropdown.vue';
import TextInput from '@/Vue/Common/TextInput.vue';
import RequestError from '@/Errors/RequestError';
import EventType from '@/Utility/EventType';
import AuthorizationError from '@/Errors/AuthorizationError';
import type {TenantRole} from '@/Models/Tenant/TenantRole';
import {Permission} from '@/Models/User/Permission';

export default defineComponent({
    components: {
        TextInput,
        Dropdown,
        ButtonPrimary,
    },

    props: {
        tenantRoles: {
            type: Array as PropType<TenantRole[]>,
            required: true,
        },
    },

    data() {
        return {
            key: shortId(),
            tenantService: inject(tenantServiceKey)!,
            form: {
                email: null as string | null,
                tenant_role_uid: null as string | null,
            },
            inviteUsers: true,
            errors: {},
            user: window.currentUser,
        };
    },

    computed: {

        buttonCaption() {
            return this.inviteUsers ? 'labels.invite' : 'labels.add';
        },

        canAddUsers() {
            return this.$gate.allows(Permission.TenantsAddUsers());
        },

        canInviteUsers() {
            return this.$gate.allows(Permission.TenantInvitationsCreate());
        },

        isSaving() {
            if (this.tenantService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            } else {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                return false;
            }
        },

        tenant() {
            return this.user?.tenant;
        },

        initialTenantRole() {
            const role: TenantRole | undefined = this.tenantRoles.find(role => role.name === 'learner');

            return role ? role.uid : null;
        },

        tenantRoleOptions() {
            return this.tenantRoles.map(role => new DropdownOption({
                    caption: role.caption,
                    disabled: false,
                    value: role.uid
                })
            );
        },
    },

    watch: {
        inviteUsers: {
            immediate: true,
            deep: true,
            handler(newValue) {
                this.inviteUsers = newValue;
                this.key = shortId();
            }
        }
    },

    mounted() {
        this.form.tenant_role_uid = this.initialTenantRole;
        this.key = shortId();
    },

    methods: {
        trans,

        /**
         * Get the validation errors for a specific field.
         */
        validationErrors(property: string): Array<string> {
            return Object.prototype.hasOwnProperty.call(this.errors, property) ? this.errors[property] : [];
        },

        /**
         * Clear the validation errors for a specific field.
         */
        removeValidationError(property: string): void {
            delete this.errors[property];
        },

        addUser(_: SubmitEvent) {

            if (this.form.email === null || this.form.email.trim() === '') {
                return;
            }

            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));

            if (!this.inviteUsers && this.canAddUsers) {
                this.tenantService.addUser(this.tenant!, this.form.email!, this.form.tenant_role_uid!)
                    .then(() => {
                        this.$toast.success(trans('users.invitation.add_success'));
                        this.$globalEvents.emit(EventType.SIDEPANEL_ADD_USER_TO_TENANT_ADDED);
                    })
                    .catch(this.onErrorApi)
                    .finally(() => {
                        this.form.email = null;
                        this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                        this.key = shortId();
                    });
            } else if (this.inviteUsers && this.canInviteUsers) {
                this.tenantService.inviteUser(this.tenant!, this.form.email!, this.form.tenant_role_uid!)
                    .then(() => {
                        this.$toast.success(trans('users.invitation.invitation_success'));
                    })
                    .catch(this.onErrorApi)
                    .finally(() => {
                        this.form.email = null;
                        this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
                        this.key = shortId();
                    });
            }
        },

        onErrorApi(error: any) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root?.forceLogout;
            }

            if (error instanceof RequestError && error.isValidationError) {
                this.errors = error.validationErrors;
            } else {
                this.$root!.showErrorDialog(error);
            }
        },
    }
});
</script>

<style lang="scss" scoped>

    .property-headline {
        margin-top: var(--sidepanel-padding);

        h3 {
            text-transform: capitalize;
        }
    }

    .property-submit-button {
        display: flex;
        flex-direction: row-reverse;
        justify-content: space-between;
        align-items: center;

        .checkbox-invite {
            width: max-content;
        }
    }
</style>
