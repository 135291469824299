<template>
    <div class="row">
        <div>
            <IdentityProviderLogoLink
                v-if="user.identity_provider"
                :provider="user.identity_provider"
                class="identity-provider-link"
            />
        </div>
        <div class="active-state">
            <span
                :class="activeClass"
                :title="activeTooltip"
            />
        </div>
        <div>
            {{ user.fullName }}
        </div>
        <div>
            <a v-if="canUpdate" :href="route('users.updateForm', {'user': user.uid})">{{ user.email }}</a>
            <span v-else>{{ user.email }}</span>
        </div>
        <div>
            {{ user.roleLabel }}
        </div>
        <time :datetime="moment(user.created_at).format()">
            {{ user.created_at.toLocaleString() }}
        </time>
        <time :datetime="moment(user.updated_at).format()">
            {{ user.updated_at.toLocaleString() }}
        </time>
        <div class="tenants">
            <ul>
                <li
                    v-for="(tenantMembership, index) in user.tenant_member_roles"
                    :key="'user-'+user.uid+'tenant-'+index"
                    class="tenant-row"
                >
                    <span v-if="tenantMembership.scim_group" :title="tenantMembership.scim_group.name">
                        <Icon name="icon_identity-provider" />
                    </span>
                    {{ tenantMembership.tenant?.name }} ({{ trans('tenant_roles.' + tenantMembership.role.name) }})
                </li>
            </ul>
        </div>
        <div>
            <ButtonCircular
                :disabled="!canDelete"
                :title="trans('labels.delete')"
                icon="icon_delete"
                @trigger="onDeleteClick"
            />
        </div>
    </div>
</template>

<script lang="ts">

import {route, trans} from '@/Utility/Helpers';
import type {PropType} from 'vue';
import {defineComponent} from 'vue';
import type User from '@/Models/User/User';
import ButtonCircular from '@/Vue/Common/ButtonCircular.vue';
import {Permission} from '@/Models/User/Permission';
import IdentityProviderLogoLink from '@/Vue/IdentityProviders/IdentityProviderLogoLink.vue';
import Icon from '@/Vue/Common/Icon.vue';

export default defineComponent({
    components: {
        Icon,
        IdentityProviderLogoLink,
        ButtonCircular
    },

    props: {
        user: {
            type: Object as PropType<User>,
            required: true,
        },
    },

    emits: {
        'delete': (_: User) => true,
    },

    computed: {
        canUpdate() {
            return this.$gate.allows(Permission.ability(Permission.UsersUpdate()), this.user);
        },

        canDelete() {
            return this.$gate.allows(Permission.ability(Permission.UsersDeleteInstanceWide()), this.user);
        },

        activeClass() {
            return this.user.active ? 'user-activated' : 'user-deactivated';
        },

        activeTooltip() {
            return this.user.active ? trans('labels.active') : trans('labels.deactivated');
        }
    },

    methods: {
        route,
        trans,

        onDeleteClick() {
            this.$emit('delete', this.user);
        },
    }
});
</script>

<style lang="scss" scoped>

.row {
    > * {
        background: white;
        padding: 10px;

        a {
            font-family: var(--font-family-condensed-demibold);
        }

        &:first-child {
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
        }

        &:last-child {
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        &.tenants ul {
            padding-left: 14px;

            .icon {
                width: 20px;
                height: 20px;
            }
        }
    }

    .identity-provider-link {
        width: 24px;
    }

    .active-state {
        padding-left: 0;
        padding-right: 0;
    }

    span.user-activated,
    span.user-deactivated {
        &:before {
            content: "";
            display: block;
            width: 7px;
            height: 7px;
            border-radius: 14px;
            background-color: var(--color-primary);
            margin-top: 7px;
            margin-left: auto;
            margin-right: auto;
        }
    }

    span.user-deactivated {
        &:before {
            background-color: var(--color-anthracite40);
        }
    }
}

</style>
