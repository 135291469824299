<template>
    <div :data-busy="isBusy">
        <NoItemsAvailable v-if="groups.length >= 1 && assignedGroups.length === 0" />
        <table v-else-if="assignedGroups.length >= 1">
            <thead>
                <tr>
                    <th />
                    <th>{{ trans('labels.members') }}</th>
                    <th v-for="role in tenantRoles" :key="'group-role-'+role.name" class="checkmark">
                        {{ role.caption }}
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="group in assignedGroups"
                    :key="'group-role-assignment-'+group.name+'-'+group.tenant_role?.uid"
                >
                    <td
                        :title="group.tenant_role?.caption"
                        class="group-name"
                    >
                        {{ group.name }}
                    </td>
                    <td class="members-count">
                        {{ group.members_count }}
                    </td>
                    <td
                        v-for="role in tenantRoles"
                        :key="'group-role-assignment-'+role.name"
                        :class="cellClass(group, role)"
                        :title="cellTitle(group, role)"
                        @click="toggleGroup(group, role)"
                    >
                        <Icon class="checked" name="icon_check" />
                        <Icon class="remove" name="icon_remove" />
                    </td>
                </tr>
            </tbody>
        </table>

        <Teleport to="#layout-inspector">
            <div class="inspector-panel">
                <div class="property property-headline">
                    <h3>{{ trans('tenants.manage_groups.add_group_headline') }}</h3>
                    <p>{{ trans('tenants.manage_groups.add_group_description') }}</p>
                </div>

                <div class="property">
                    <Dropdown
                        :key="'add-group-to-team-'+assignedGroups.length"
                        ref="unassignedGroups"
                        :label="trans('labels.group')"
                        :model="this"
                        :options="unassignedGroupsOptions"
                        :required="true"
                        property="selectedGroup"
                    />
                </div>

                <div class="property">
                    <Dropdown
                        ref="groupTenantRole"
                        :label="trans('labels.tenant_role')"
                        :model="this"
                        :options="tenantRoleOptions"
                        :required="true"
                        property="selectedRole"
                    />
                </div>

                <div class="property property-submit-button">
                    <ButtonPrimary
                        :disabled="isAddButtonDisabled"
                        caption="labels.add"
                        @trigger="addGroupFromDropdown"
                    />
                </div>
            </div>
        </Teleport>

        <ModalApplyCancel
            :description="modalOptions.description"
            :title="modalOptions.title"
            event-type="MODAL_TENANT_MANAGE_GROUP"
        />
    </div>
</template>

<script lang="ts">

import AuthorizationError from '@/Errors/AuthorizationError';
import {permission, route, trans} from '@/Utility/Helpers';
import {Permission} from '@/Models/User/Permission';
import type {PropType} from 'vue';
import {defineComponent, inject} from 'vue';
import {tenantServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import EventType from '@/Utility/EventType';
import ScimGroup from '@/Models/SCIM/ScimGroup';
import type {TenantRole} from '@/Models/Tenant/TenantRole';
import Icon from '@/Vue/Common/Icon.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import Dropdown from '@/Vue/Common/Dropdown.vue';
import DropdownOption from '@/Utility/DropdownOption';
import NoItemsAvailable from '@/Vue/Search/NoItemsAvailable.vue';
import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';

interface ModalOptions {
    title: string | null,
    description: string | null,
    callback: ((...args: any[]) => any) | null,
}

export default defineComponent({


    components: {
        ModalApplyCancel,
        NoItemsAvailable,
        Dropdown,
        ButtonPrimary,
        Icon
    },

    props: {
        tenantRoles: {
            type: Array as PropType<TenantRole[]>,
            default() {
                return [];
            }
        },
    },

    data() {
        return {
            tenantService: inject(tenantServiceKey)!,
            groups: [] as ScimGroup[],
            selectedGroup: null as ScimGroup | null,
            selectedRole: this.tenantRoles[this.tenantRoles.length - 1] || null as TenantRole | null,
            modalOptions: {
                title: null as string | null,
                description: null as string | null,
                callback: null as ((...args: any[]) => any) | null,
            } as ModalOptions,
        };
    },

    computed: {

        assignedGroups(): ScimGroup[] {
            return this.groups.filter(group => group.tenant_role !== null);
        },

        unassignedGroupsOptions(): DropdownOption[] {
            return this.groups.filter(group => group.tenant_role === null).map(group => new DropdownOption({
                caption: group.name,
                value: group,
            }));
        },

        canManageGroups(): boolean {
            return permission(Permission.TenantsManageGroups());
        },

        isAddButtonDisabled(): boolean {
            return !this.selectedGroup || !this.selectedRole;
        },

        isBusy(): boolean {
            if (this.tenantService.isLoading) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
                return true;
            }
            if (this.tenantService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            }
            if (this.tenantService.isDeleting) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.removing_group'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        tenantRoleOptions(): DropdownOption[] {
            return this.tenantRoles.map(role => new DropdownOption({
                    caption: role.caption,
                    value: role,
                })
            );
        },
    },

    mounted() {
        this.tenantService.fetchGroupsForTenant().then(this.onSuccessFetchGroups).catch(this.onErrorApi);

        this.$globalEvents.on(EventType.MODAL_TENANT_MANAGE_GROUP_APPLY, this.onModalClickApply);
        this.$globalEvents.on(EventType.MODAL_TENANT_MANAGE_GROUP_HIDE, this.onModalHide);
    },

    beforeUnmount() {
        this.$globalEvents.off(EventType.MODAL_TENANT_MANAGE_GROUP_APPLY, this.onModalClickApply);
        this.$globalEvents.off(EventType.MODAL_TENANT_MANAGE_GROUP_HIDE, this.onModalHide);
    },

    methods: {
        trans,
        route,

        cellClass(group: ScimGroup, role: TenantRole): string {
            return 'checkmark ' + ((group.tenant_role?.name === role.name) ? 'is-active' : 'is-inactive');
        },

        cellTitle(group: ScimGroup, role: TenantRole | null = null): string {
            return (group.tenant_role?.name === role!.name)
                ? trans('tenants.manage_groups.remove_role', { role: trans('tenant_roles.' + role!.name) })
                : trans('tenants.manage_groups.set_role', { role: trans('tenant_roles.' + role!.name) });
        },

        onSuccessFetchGroups(groups: ScimGroup[]): void {
            this.groups = groups;
        },

        onErrorApi(error: any): void {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root?.forceLogout;
            }

            this.$root?.showErrorDialog(error);
        },

        onModalClickApply(options: ModalOptions) {
            options.callback?.apply(this);
        },

        onModalHide() {
            this.modalOptions.callback = null;
        },

        addGroupFromDropdown(): void {
            if (this.selectedGroup === null || this.selectedRole === null) {
                this.$root?.showErrorDialog(trans('tenants.manage_groups.error_no_selection'));
                return;
            }

            this.modalOptions.title = trans('tenants.manage_groups.modal_add.title');
            this.modalOptions.description = trans('tenants.manage_groups.modal_add.description', {
                group: this.selectedGroup.name,
                membersCount: this.selectedGroup.members_count,
                team: window.currentUser!.tenant!.name,
                role: this.selectedRole.caption,
            });
            this.modalOptions.callback = () => {
                this.tenantService
                    .addGroupToTenant(this.selectedGroup!, this.selectedRole!)
                    .then(this.onGroupAdded)
                    .catch(this.onErrorApi);
            };
            this.$globalEvents.emit(EventType.MODAL_TENANT_MANAGE_GROUP_SHOW, this.modalOptions);
        },

        toggleGroup(group: ScimGroup, role: TenantRole): void {

            const descriptionStrings = {
                group: group.name,
                membersCount: group.members_count,
                team: window.currentUser!.tenant!.name,
                role: role.caption,
            };

            // Remove
            if (role.name === group.tenant_role?.name) {

                this.modalOptions.title = trans('tenants.manage_groups.modal_remove.title');
                this.modalOptions.description =
                    trans('tenants.manage_groups.modal_remove.description', descriptionStrings);
                this.modalOptions.callback = () => {
                    this.tenantService
                        .removeGroupFromTenant(group)
                        .then(() => {
                            const index = this.groups.findIndex(g => g.uid === group.uid);
                            this.groups.splice(index, 1, new ScimGroup({
                                ...group,
                                tenant_role: null,
                            }));
                            this.$toast.success(trans(
                                'tenants.manage_groups.modal_remove.success',
                                descriptionStrings
                            ));
                        }).catch(this.onErrorApi);
                };
                this.$globalEvents.emit(EventType.MODAL_TENANT_MANAGE_GROUP_SHOW, this.modalOptions);
                return;
            }

            // Add/Update
            if (group.tenant_role === null) {
                this.modalOptions.title = trans('tenants.manage_groups.modal_add.title');
                this.modalOptions.description =
                    trans('tenants.manage_groups.modal_add.description', descriptionStrings);

            } else {
                this.modalOptions.title = trans('tenants.manage_groups.modal_change.title');
                this.modalOptions.description = trans('tenants.manage_groups.modal_change.description', {
                    ...descriptionStrings,
                    oldRole: group.tenant_role.caption,
                });
            }

            this.modalOptions.callback = () => {
                this.tenantService
                    .addGroupToTenant(group, role)
                    .then(this.onGroupAdded)
                    .catch(this.onErrorApi);
            };

            this.$globalEvents.emit(EventType.MODAL_TENANT_MANAGE_GROUP_SHOW, this.modalOptions);
        },

        onGroupAdded(group: ScimGroup): void {
            this.selectedGroup = null;
            const index = this.groups.findIndex(g => g.uid === group.uid);
            const descriptionStrings = {
                group: group.name,
                role: group.tenant_role!.caption,
            };
            if (this.groups[index]?.tenant_role === null) {
                this.$toast.success(trans('tenants.manage_groups.modal_add.success', descriptionStrings));
            } else {
                this.$toast.success(trans('tenants.manage_groups.modal_change.success', descriptionStrings));
            }
            this.groups.splice(index, 1, group);
        },
    }
});
</script>

<style lang="css" scoped>

table {
    margin-top: 16px;
    background-color: var(--background-color-white);
}

thead {
    background-color: var(--color-anthracite);
    color: var(--font-color-light);
}

th {
    padding: 4px 8px;
    font-weight: normal;
}

th:nth-child(-n+2) {
    border: 0 solid var(--color-anthracite80);
    border-right-width: 1px;
    border-left-width: 1px;
}

td {
    border: 1px solid var(--color-anthracite20);
}

th.checkmark,
td.checkmark {
    width: 100px;
    text-align: center;
}

td.group-name {
    min-width: 300px;
    padding: 0 16px;
    transition: color .1s;
    font-family: var(--font-family-condensed-demibold);
}

td.members-count {
    text-align: center;
}

tr:hover td {
    background-color: var(--color-anthracite10);
}

td.checkmark {
    cursor: pointer;

    &:deep(.icon) {
        transition: none;
    }

    &.is-active {
        .icon.remove {
            display: none;
        }

        &:hover {
            .icon.checked {
                display: none;
            }

            .icon.remove {
                display: inline-block;
            }
        }
    }

    &.is-inactive {
        .icon.remove {
            display: none;
        }
    }

    &.is-inactive :deep(.icon) {
        opacity: 0;
    }

    &:hover {
        background-color: var(--color-primary-hover);
    }

    &:hover :deep(.icon) {
        opacity: 1;
        color: var(--color-white);
    }
}

.property-headline {
    margin-top: var(--sidepanel-padding);

    h3 {
        text-transform: capitalize;
    }
}

.property-submit-button {
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    align-items: center;

    .checkbox-invite {
        width: max-content;
    }
}

.no-items-available {
    margin-top: 10vh;
}
</style>
