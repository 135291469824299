<template>
    <svg v-if="name" class="icon">
        <use :xlink:href="'#' + name" />
    </svg>
</template>

<script lang="ts">
import type {PropType} from 'vue';

export default {
    name: 'Icon',

    props: {
        /**
         * SVG icon identifier name (e.g. "icon_search, icon_doublearrow_right")
         */
        name: {
            type: null as PropType<string | null>,
            required: true,
        }
    }
};
</script>

<style lang="css" scoped>

</style>
