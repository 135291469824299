<template>
    <main
        id="layout-main"
        :data-saving="isSaving"
    >

        <div id="layout-content">
            <div id="content">

                <div class="column">
                    <form :key="identityProvider.updated_at.getTime()" ref="form">

                        <h1 class="headline">
                            {{ trans('identity_providers.update.headline') }}
                        </h1>

                        <div class="fields">
                            <div class="driver">
                                <label>{{ trans('labels.authorization_driver') }}</label>
                                <img
                                    :alt="identityProvider.driver.name"
                                    :src="identityProvider.driver.logo"
                                    :title="identityProvider.driver.name"
                                >
                            </div>

                            <TextInput
                                :label="trans('labels.name')"
                                :maxlength="255"
                                :model="form"
                                :placeholder="trans('identity_providers.placeholders.name')"
                                :validation-errors="validationErrors('name')"
                                property="name"
                                required
                                @change="removeValidationErrors('name')"
                            />

                            <TextInput
                                :label="trans('labels.domain')"
                                :maxlength="255"
                                :model="form"
                                :placeholder="trans('identity_providers.placeholders.domain')"
                                :validation-errors="validationErrors('domain')"
                                property="domain"
                                required
                                @change="removeValidationErrors('domain')"
                            />

                            <TextInput
                                :label="trans('labels.client_id')"
                                :maxlength="255"
                                :model="form"
                                :placeholder="trans('identity_providers.placeholders.client_id')"
                                :validation-errors="validationErrors('client_id')"
                                property="client_id"
                                required
                                @change="removeValidationErrors('client_id')"
                            />

                            <TextInput
                                :label="trans('labels.client_secret')"
                                :maxlength="255"
                                :model="$data"
                                :placeholder="trans('identity_providers.placeholders.client_secret')"
                                :validation-errors="validationErrors('client_secret')"
                                property="clientSecret"
                                required
                                type="password"
                                @change="removeValidationErrors('client_secret')"
                                @focus="e => e.target.select()"
                            />

                            <TextInput
                                :label="trans('labels.entra_tenant_id')"
                                :maxlength="255"
                                :model="form.configuration"
                                :placeholder="trans('identity_providers.placeholders.tenant')"
                                :validation-errors="validationErrors('configuration.tenant')"
                                property="tenant"
                                required
                                @change="removeValidationErrors('configuration.tenant')"
                            />

                            <TextInput
                                :label="trans('labels.proxy')"
                                :maxlength="255"
                                :model="form.configuration"
                                :placeholder="trans('identity_providers.placeholders.proxy')"
                                :validation-errors="validationErrors('configuration.proxy')"
                                property="proxy"
                                type="url"
                                @change="removeValidationErrors('configuration.proxy')"
                            />
                        </div>

                        <div class="buttons">
                            <ButtonSecondary
                                :href="showRouteUrl"
                                caption="identity_providers.update.btn_cancel"
                            />
                            <ButtonPrimary :caption="trans('labels.save')" @trigger.prevent="onSave" />
                        </div>
                    </form>
                </div>

                <div class="column">
                    <identity-provider-help />
                </div>

            </div>

            <!-- Modals go here -->
            <ModalProgress />
            <ModalNotification />
        </div>

    </main>
</template>

<script lang="ts">
import {defineComponent, inject} from 'vue';
import IdentityProvider from '@/Models/IdentityProviders/IdentityProvider';
import {route, trans} from '@/Utility/Helpers';
import TextInput from '@/Vue/Common/TextInput.vue';
import ButtonPrimary from '@/Vue/Common/ButtonPrimary.vue';
import ModalProgress from '@/Vue/Modals/ModalProgress.vue';
import {identityProviderServiceKey} from '@/Vue/Bootstrap/InjectionKeys';
import type RequestError from '@/Errors/RequestError';
import EventType from '@/Utility/EventType';
import ModalNotification from '@/Vue/Modals/ModalNotification.vue';
import ButtonSecondary from '@/Vue/Common/ButtonSecondary.vue';
import AuthorizationError from '@/Errors/AuthorizationError';
import IdentityProviderHelp from '@/Vue/IdentityProviders/IdentityProviderHelp.vue';

export default defineComponent({
    components: {
        IdentityProviderHelp,
        ButtonSecondary,
        ModalNotification,
        ModalProgress,
        ButtonPrimary,
        TextInput,
    },

    props: {
        identityProviderJson: {
            type: String,
            required: true,
        },
    },

    data() {
        const identityProvider = new IdentityProvider(JSON.parse(this.identityProviderJson));
        const secretPlaceholder = 'xxxxxxxx';

        return {
            identityProviderService: inject(identityProviderServiceKey)!,
            identityProvider: identityProvider,
            form: identityProvider.toUpdateIdentityProviderParameters(),
            errors: {} as Record<string, string[]>,
            secretPlaceholder: secretPlaceholder,
            clientSecret: secretPlaceholder,

            events: new Map([
                [EventType.WINDOW_BEFORE_UNLOAD, this.onBeforeUnload],
            ]),
        };
    },

    computed: {
        formHtml() {
            return this.$refs.form as HTMLFormElement;
        },

        isSaving() {
            if (this.identityProviderService.isSaving) {
                this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.saving'));
                return true;
            }
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_HIDE);
            return false;
        },

        showRouteUrl() {
            return route('identity_providers.show', { identity_provider: this.identityProvider.uid });
        },
    },

    mounted() {
        // Add global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.on(key, value);
        });
    },

    beforeUnmount() {
        // Remove global events:
        this.events.forEach((value, key) => {
            this.$globalEvents.off(key, value);
        });
    },

    methods: {
        route,
        trans,

        validationErrors(property: string): string[] {
            return this.errors[property] || [];
        },

        removeValidationErrors(property: string) {
            delete this.errors[property];
        },

        onSave() {
            if (!this.formHtml.reportValidity()) {
                return;
            }

            if (this.clientSecret !== this.secretPlaceholder) {
                this.form.client_secret = this.clientSecret;
            }

            this.identityProviderService
                .updateIdentityProvider(this.identityProvider.uid, this.form)
                .then(provider => {
                    this.identityProvider = provider;
                    this.form = provider.toUpdateIdentityProviderParameters();
                })
                .catch(this.onApiError);
        },

        onApiError(error: RequestError | AuthorizationError) {
            // Force logout for authorization errors:
            if (error instanceof AuthorizationError) {
                error.callback = this.$root!.forceLogout;
                return;
            }

            if (error.isValidationError) {
                this.errors = error.validationErrors;
                return;
            }

            this.$root!.showErrorDialog(error);
        },

        onBeforeUnload() {
            this.identityProviderService.cancelRequests();
            this.$globalEvents.emit(EventType.MODAL_PROGRESS_SHOW, trans('modals.progress.loading'));
        },
    }
});
</script>

<style lang="css" scoped>

#content {
    display: grid;
    grid-template-columns: minmax(min-content, 700px) minmax(min-content, 480px);
    justify-content: center;
    gap: 32px;
    padding: 32px;

    .column {
        display: flex;
        flex-direction: column;
        gap: 32px;
    }

    form {
        background-color: white;
        border-radius: var(--card-border-radius);
        padding: 30px 48px;

        .headline {
            margin-bottom: 32px;
            text-align: center;
        }

        .fields {
            .driver {
                display: flex;
                flex-direction: column;
                text-align: center;
                margin-bottom: var(--forminput-spacing);

                label {
                    font-family: var(--font-family-condensed-demibold);
                    font-size: var(--font-size-default);
                    margin-bottom: 8px;
                }

                img {
                    margin: auto;
                    width: 80px;
                    padding: 8px;
                    aspect-ratio: 1;
                    border: var(--forminput-border);
                    border-radius: var(--forminput-border-radius);
                }
            }

            .textinput {
                &:deep(label) {
                    padding: 0 0 8px 0;
                    font-family: var(--font-family-condensed-demibold);

                    &:has(+ :required):after {
                        content: ' *';
                    }
                }
            }
        }

        .buttons {
            margin-top: 32px;
            display: flex;
            justify-content: end;

            &.center {
                justify-content: center;
            }
        }
    }
}

</style>
