<template>
    <ModalApplyCancel
        :description="description"
        :title="trans('users.remove.modals.confirm.title')"
        apply-text="users.remove.modals.confirm.apply"
        event-type="MODAL_REMOVE_USER"
        @show="onShow"
    />
</template>

<script lang="ts">
import {defineComponent} from 'vue';
import ModalApplyCancel from '@/Vue/Modals/ModalApplyCancel.vue';
import {trans} from '@/Utility/Helpers';
import type User from '@/Models/User/User';
import type Tenant from '@/Models/Tenant/Tenant';

export default defineComponent({
    components: {
        ModalApplyCancel
    },

    data() {
        return {
            user: null as User | null,
            tenant: null as Tenant | null,
        };
    },

    computed: {
        description() {
            if (this.user === null || this.tenant === null) {
                return '';
            }

            const scimMappingRole = this.user.getHighestScimTenantRole(this.tenant);

            if (scimMappingRole) {
                return trans(
                    'users.remove.modals.confirm.description_scim_mapped',
                    {
                        'firstname': this.user.firstname!,
                        'lastname': this.user.lastname!,
                        'email': this.user.email,
                        'scim_role': scimMappingRole.caption,
                    }
                );
            }

            const translationKey = this.user.belongsToMultipleTenants() ?
                'users.remove.modals.confirm.description' : 'users.remove.modals.confirm.description_last_team';

            return trans(
                translationKey,
                { 'firstname': this.user.firstname!, 'lastname': this.user.lastname!, 'email': this.user.email }
            );
        }
    },

    methods: {
        trans,

        onShow(user: User, tenant: Tenant) {
            this.user = user;
            this.tenant = tenant;
        }
    }
});
</script>

<style lang="css" scoped>

</style>
