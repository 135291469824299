import {parseDate} from '@/Utility/Helpers';
import User from '@/Models/User/User';
import Tenant from '@/Models/Tenant/Tenant';
import {TenantRole} from '@/Models/Tenant/TenantRole';
import IdentityProviderBasics from '@/Models/IdentityProviders/IdentityProviderBasics';

export default class ScimGroup {

    get constructorName() {
        return 'ScimGroup';
    }

    public readonly uid: string;
    public readonly name: string;
    public readonly external_id: string | null;
    public readonly provider_uid: string | null;
    public readonly identity_provider: IdentityProviderBasics | null;
    public readonly members: User[];
    public readonly members_count: number;
    public readonly tenants: Tenant[];
    public readonly tenant_role: TenantRole | null;
    public readonly created_at: Date;
    public readonly updated_at: Date;

    constructor(attributes: any) {
        this.uid = attributes.uid;
        this.name = attributes.name;
        this.external_id = attributes.external_id;
        this.provider_uid = attributes.provider_uid;
        this.identity_provider = attributes.identity_provider ? new IdentityProviderBasics(attributes.identity_provider) : null;
        this.members = (attributes.members || []).map((member: any) => new User(member));
        this.members_count = attributes.members_count || 0;
        this.tenants = (attributes.tenants || []).map((tenant: any) => new Tenant(tenant));
        this.tenant_role = attributes.tenant_role ? new TenantRole(attributes.tenant_role) : null;
        this.created_at = parseDate(attributes.created_at)!;
        this.updated_at = parseDate(attributes.updated_at)!;
    }
}
