import {parseDate, sortArrayByProperty, trans} from '@/Utility/Helpers';
import Tenant from '@/Models/Tenant/Tenant';
import {TenantRole} from '@/Models/Tenant/TenantRole';
import type {UserRole} from '@/Models/User/UserRole';
import {TenantMemberRole} from '@/Models/Tenant/TenantMemberRole';
import {getInitial} from '@/Utility/Helpers/getInitial';
import IdentityProviderBasics from '@/Models/IdentityProviders/IdentityProviderBasics';

export default class User {

    static get constructorName(): string {
        return 'User';
    }

    public readonly uid: string;

    public readonly email: string;
    public readonly firstname: string | null;
    public readonly lastname: string | null;

    /**
     * Full URL pointing to the users profile image.
     */
    public readonly image: string;

    /**
     * Full URL pointing to the users character avatar URL.
     */
    public readonly avatar_url: string | null;

    public readonly role: UserRole;

    public readonly is_managed_user: boolean;
    public readonly is_lms_user: boolean;
    public readonly identity_provider: IdentityProviderBasics | null | undefined;

    public readonly active: boolean;

    public readonly created_at: Date;
    public readonly updated_at: Date;

    /**
     * List of user permissions/scopes for the current tenant.
     */
    public readonly permissions: string[];

    /**
     * Current tenant.
     */
    public tenant: Tenant | null;

    /**
     * This user's role in the current tenant.
     */
    public readonly tenant_role: TenantRole | null;

    /**
     * This user's roles in different tenants.
     */
    public readonly tenant_member_roles: TenantMemberRole[] | null;

    public readonly tenants: Tenant[];

    public readonly tenant_count: number | null;

    public selected: boolean = false;

    constructor(attributes: any = {}) {
        this.uid = attributes.uid;
        this.email = attributes.email;
        this.firstname = attributes.firstname || null;
        this.lastname = attributes.lastname || null;
        this.image = attributes.image;
        this.avatar_url = attributes.avatar_url || null;
        this.role = attributes.role || attributes.userrole?.name;
        this.is_managed_user = attributes.is_managed_user;
        this.is_lms_user = attributes.is_lms_user;
        this.identity_provider = (attributes.identity_provider instanceof Object) ?
            new IdentityProviderBasics(attributes.identity_provider) : attributes.identity_provider;
        this.active = attributes.active;
        this.created_at = parseDate(attributes.created_at)!;
        this.updated_at = parseDate(attributes.updated_at)!;
        this.permissions = Array.from(attributes.permissions || []);
        this.tenant = (attributes.tenant instanceof Object) ? new Tenant(attributes.tenant) : null;
        this.tenant_role = (attributes.tenant_role instanceof Object) ? new TenantRole(attributes.tenant_role) : null;
        this.tenants = sortArrayByProperty(attributes.tenants || [], 'name');
        this.tenant_member_roles = attributes.tenant_member_roles?.map(member => new TenantMemberRole(member)) || null;
        this.tenant_count = attributes.tenant_count !== undefined ? attributes.tenant_count : null;

        // hide selected property in serialization
        Object.defineProperty(this, 'selected', { enumerable: false, writable: true });
    }

    get constructorName(): string {
        return User.constructorName;
    }

    get fullName(): string {
        const name: string = `${this.firstname || ''} ${this.lastname || ''}`.trim();
        return (name !== '') ? name : trans('labels.unknown_user');
    }

    get hasProfileImage(): boolean {
        return !this.image.endsWith('images/preview/user.jpg');
    }

    get initials(): string {
        return getInitial(this.firstname) + getInitial(this.lastname);
    }

    get roleLabel(): string {
        return trans('userroles.' + this.role);
    }

    /**
     * Does the user have a specific permission?
     */
    hasPermission(permission: string): boolean {
        return this.permissions.some(p => p === permission);
    }

    /**
     * Is the given tenant the users current tenant?
     */
    isCurrentTenant(tenant: Tenant): boolean {
        return this.tenant?.uid === tenant.uid;
    }

    hasAnyRole(...roles: UserRole[]): boolean {
        return roles.includes(this.role);
    }

    compareByLastname(other: User): number {
        return this.lastname?.localeCompare(other.lastname || '') || 0;
    }

    belongsToMultipleTenants(): boolean {
        return (
            this.tenant_count !== null
            && this.tenant_count > 1
        );
    }

    /**
     * @return true if this user is a regular - not scim mapped - member of the given tenant.
     * A user can be both - a regular member *and* a scim mapped one.
     */
    isRegularMemberOfTenant(tenant: Tenant): boolean {
        return this.getHighestRegularTenantRole(tenant) !== null;
    }

    /**
     * @return The highest tenant role this user has inside the given tenant as regular member (not due to a scim mapping)
     * or null if not scim mapped.
     * A user can be both - a regular member *and* a scim mapped one.
     */
    getHighestRegularTenantRole(tenant: Tenant): TenantRole | null {
        if (this.tenant_member_roles == null) {
            throw new Error('Cannot determine tenant membership when tenant_member_roles are not set.');
        }

        return this.tenant_member_roles.find((role: TenantMemberRole) =>
            role.tenant_uid === tenant.uid && !role.isScimMapped
        )?.role || null;
    }

    /**
     * @return The highest tenant role this user has inside the given tenant due to a scim mapping
     * or null if no regular member.
     * A user can be both - a regular member *and* a scim mapped one.
     */
    getHighestScimTenantRole(tenant: Tenant): TenantRole | null {
        if (this.tenant_member_roles == null) {
            throw new Error('Cannot determine tenant membership when tenant_member_roles are not set.');
        }

        return this.tenant_member_roles.find((role: TenantMemberRole) =>
            role.tenant_uid === tenant.uid && role.isScimMapped
        )?.role || null;
    }
}
